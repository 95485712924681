const jsonAmigos = {
	"amigos": [
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "01",
			"nombre": "Violeta Rosales Hernandez",
			"correo": "violetisha@gmail.com"
		},
		{
			"id": "02",
			"nombre": "Omar Cornejo Perez",
			"correo": "violetisha@gmail.com"
		}
	]
}

const jsonLugares = {
	"lugares": [
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "01",
			"nombre": "Chilis",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		},
		{
			"id": "02",
			"nombre": "Las alitas",
			"direccion": "Calle Camino a Nextengo 78, Santa Cruz Acayucan, 02770 Ciudad de México, CDMX "
		}
	]
}

$(function() {


	// Pantallitas con transicion
	$('.transition').click(function(event) {
		event.preventDefault();
		$('.card').removeClass('active');
		let theTarget = $(this).attr('href');
		$(theTarget).addClass('active');
	});

	// Muestra y oculta infografia
	$('.info-button').click(function(event) {
		event.preventDefault();
		$('.infographic').toggleClass('active');
		$(this).toggleClass('close');
	});


	// Muestra las direcciones de los lugares
	$('.list-places >.field').click(function(event) {
		$('.infographic').toggleClass('active');
		$(this).toggleClass('close');
	});




	function getAmigos() {
		for (let i = 0; i < jsonAmigos.amigos.length; i++) {
			$('.list-friends').append(`
				<div class="field">
					<input class="is-checkradio is-circle" type="radio" name="amigos" id="amigo${jsonAmigos.amigos[i].id}">
					<label for="amigo${jsonAmigos.amigos[i].id}">${jsonAmigos.amigos[i].nombre}</label>
				</div>
			`);
		}
	}

	function getLugares() {
		for (let i = 0; i < jsonLugares.lugares.length; i++) {
			$('.list-places').append(`
				<div class="field">
					<input class="is-checkradio is-circle" type="radio" name="lugares" id="lugar${jsonLugares.lugares[i].id}">
					<label for="lugar${jsonLugares.lugares[i].id}">
						${jsonLugares.lugares[i].nombre}
						<small>${jsonLugares.lugares[i].direccion}</small>
					</label>
				</div>
			`);
		}
	}

	getAmigos();
	getLugares();


});
